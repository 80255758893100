import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import * as yup from 'yup';
import { Country, State }  from 'country-state-city';

import { Input } from "../../components/ui/Input";
import {
  FormContainer,
  LoginForm,
  MainTitle,
  SubmitButton,
  SmallText,
} from "../../components/MainStyles";
import logo from "../../nurtur_new_logo.svg";
import Footer from "../../components/Footer";
import styles from "./styles";
import { Tooltip } from "react-tooltip";
import { Autocomplete, TextField } from "@mui/material";


const formValidation = yup.object().shape({
  firstname: yup
    .string()
    .required('Please enter first name')
    .min(1, 'Please enter first name'),
  lastname: yup
    .string()
    .required('Please enter last name')
    .min(1, 'Please enter last name'),
  password: yup
    .string()
    .required('Please enter your password')
    .min(8, 'Must be atleast 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[^\w]/, 'Password requires a symbol'),
  email: yup
    .string()
    .lowercase()
    .trim()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email address")
    .required('Please enter your email')
});

const SignUp = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [countries, setCountries] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [states, setStates] = useState(null);
  const [selectedState, setSelectedState] = useState({});


  useEffect(() => {
    const countries = Country.getAllCountries();
    const allCountries = countries.map((country) => ({
      label: country.name,
      value: country.isoCode,
    }));
    setCountries(allCountries);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const states = State.getStatesOfCountry(selectedCountry.value).map((state) => ({ label: state.name, value: state.isoCode }));
      setStates(states);
    }
  }, [selectedCountry])

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      // Replace the URL with your FastAPI endpoint
      const response = await axios.post(process.env.REACT_APP_BASE_URL + '/register', {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        password: values.password,
        country: selectedCountry.value,
        state: selectedState.value
      });
      if (response.status < 400){
        const { email } = response.data.cosmos_user
        await axios.post(process.env.REACT_APP_BASE_URL + `/epds/create`, {
          email
        });
        await axios.post(process.env.REACT_APP_BASE_URL + `/chat/create`, {
          email
        });
        // Assuming successful registration
        // navigate('/login');
        navigate('/');
      }
    } catch (error) {

      if(error.message == "Request failed with status code 400"){
        setErrorMessage('User already registered');
      }else{
        setErrorMessage(error.message);
      }
      
      
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <FormContainer>
          <img
            src={logo}
            alt="nurtur logo"
            className="logo"
            style={{ borderRadius: "10px" }}
          />
          <LoginForm>
            <MainTitle>Create an account</MainTitle>

            <Formik
              initialValues={{ firstname: '', lastname: '', email: '', password: '' }}
              validationSchema={formValidation}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Field id="firstname" type="text" name="firstname" placeholder="Enter First Name*" style={styles.formField} />
                  <ErrorMessage name="firstname" component="div" style={{ color: "red", marginLeft: 10, fontSize: 'small' }} />
                  <Field id="lastname" type="text" name="lastname" placeholder="Enter Last Name*" style={styles.formField} />
                  <ErrorMessage name="lastname" component="div" style={{ color: "red", marginLeft: 10, fontSize: 'small' }} />
                  <Field id="email" type="email" name="email" placeholder="Enter Email*" style={styles.formField} />
                  <ErrorMessage name="email" component="div" style={{ color: "red", marginLeft: 10, fontSize: 'small' }} />
                  <Field id="password" type="password" name="password" placeholder="Enter Password*" style={styles.formField} data-tooltip-id="password" data-tooltip-html="Password must be a combination of 8 letters, <br />numbers and special characters." />
                  <ErrorMessage name="password" component="div" style={{ color: "red", marginLeft: 10, fontSize: 'small' }} />
                  <Autocomplete
                      disablePortal
                      options={countries}
                      sx={{ marginLeft: 1, width: '100%', marginTop: 1 }}
                      renderInput={(params) => <TextField {...params} label="Country" />}
                      value={selectedCountry?.label}
                      onChange={(event, newValue) => {
                        setSelectedCountry(newValue)
                      }}
                    />
                    <Autocomplete
                      disablePortal
                      options={states}
                      sx={{ marginLeft: 1, width: '100%', marginTop: 1 }}
                      renderInput={(params) => <TextField {...params} label="State" />}
                      value={selectedState?.label}
                      onChange={(event, newValue) => {
                        setSelectedState(newValue)
                      }}
                    />
                  {errorMessage && <div style={{ color: 'red', marginTop: '10px', textAlign: 'center'}}>{errorMessage}</div>}
                  <SubmitButton theme="blue" type="submit" disabled={isSubmitting}>
                    Sign Up
                  </SubmitButton>
                  <SmallText style={{ marginTop: 5, marginBottom: 5 }}>
                    Already have an account?{" "}
                      <Link to="/" style={{ fontWeight: "bold", color: "#7B7FFF" }}>
                        Log In
                      </Link>
                  </SmallText>
                </Form>
              )}
            </Formik>

            <SmallText style={{ textDecoration: "underline" }}>
              <Link to="/terms">Terms of Use | Privacy Policy</Link>
            </SmallText>
          </LoginForm>
        </FormContainer>
        <Tooltip id="password" place="left"  data-tooltip-variant="info" />
      </div>
      <Footer />
    </>
  );
};

export default SignUp;