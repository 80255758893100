// Lost access to account page

import React from "react";
import { Input } from "../components/ui/Input";
import {
  FormContainer,
  LoginForm,
  MainTitle,
  SubmitButton,
  SmallText,
} from "../components/MainStyles";
import logo from "../nurtur_new_logo.svg";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address';
  }
  return errors;
};


const Forgot = () => {
  const navigate = useNavigate();

  return (
    <>
      <div style={{ position: 'relative' }}>
        <FormContainer>
        <img
          src={logo}
          alt="nurtur logo"
          className="logo"
          style={{ borderRadius: "10px" }}
        />
        <LoginForm>
          <MainTitle>Forgot Password/Lost Access</MainTitle>

          <Formik
          initialValues={{ email: '' }}
          validate={validate}
          onSubmit={values => {
            // TODO: implement login functionality here
            console.log(values);
            // navigate('/login');
            navigate('/');
          }}>
            <Form>
              <Input id="email" type="email" label="Email" name="email" />
              <SubmitButton theme="blue" type="submit">Send Account Reset Email</SubmitButton>
            </Form>
          </Formik>

          <SmallText style={{
              textDecoration: "underline",
            }}><Link to="/terms">Terms of Use | Privacy Policy</Link></SmallText>
        </LoginForm>
      </FormContainer>
    </div> 
    <Footer />
  </>
  );
};

export default Forgot;
