// Page the displays terms of service

import React from "react";
import { MainTitle } from "../components/MainStyles";
import Footer from "../components/Footer";
 
// TODO: insert TOS text here
const Terms = () => {
    return (
        <div style={{ minHeight: '100vh', position: 'relative' }}>
            <MainTitle>Terms of Service</MainTitle>
            <h1 color="707070">To be updated</h1>
            <Footer />
        </div>
    );
};
 
export default Terms;