import React from 'react';
import { Outlet } from "react-router-dom"
import AccountMenu from "../../AccountMenu";

const DefaultLayout = () => {

    return (
        <>
            <Outlet />
            <AccountMenu />
        </>
    )
}

export default DefaultLayout;