// Footer for main site pages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
// import { Foot } from "./MainStyles";
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Footer = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // const Foot = styled.footer`
  //   text-align: center; 
  //   position: absolute;
  //   width: 100%;
  //   bottom: ${windowHeight > 730 ? 0 : windowHeight * -0.1}px; // adjust this as needed
  // `;

  const Foot = styled.footer`
    text-align: center; 
    width: 100%;
    position: absolute;
    bottom: 0;
  `;

  return (
      <Foot>
        nurtur: Empowering Maternal Mental Health.
        <br />
        © 2024
      </Foot>
  );
};

// const Footer = () => {
//   return (
//     <>
//       <Foot>
//         Made with <FontAwesomeIcon style={{ color: "#656AFF" }} icon={faHeart} /> for 
//         <a href="https://nurturcare.com/" target="_blank" rel="noopener noreferrer" style={{ color: "#656AFF" }}> nurtur</a> by the 
//         <a href="https://mitechconsulting.com/" target="_blank" rel="noopener noreferrer" style={{ color: "#656AFF" }}> MITech Consulting Club</a> 
//         <br />
//         © 2024
//       </Foot>
//     </>
//   );
// };

export default Footer;
