// Provider Dashboard page
// TODO connect to database and display patient data
import React from "react";
import { TableStyle, OthersContainer, Header, TableCell, TableHeader } from "../../components/DashboardStyles";
import Table from "../../components/ui/Table";
import Footer from "../../components/Footer";

// Sample data for the table
const data = [
  { firstName: "Anom", lastName: "Darin", email: "anom_d@gmail.com", bday: "01/19/2003", weight: 149, height: "5'9\"", patientPhone: "123-123-1234", obgyn: "Dr. Edwards", doctorPhone: "123-123-1234", epds: 0, dueDate: "1/23/23"},
  { firstName: "Meghan", lastName: "Fitcher", email: "meg_f@gmail.com", bday: "03/23/1990", weight: 131, height: "5'4\"", patientPhone: "123-123-1234", obgyn: "Dr. Doe", doctorPhone: "123-123-1234", epds: 0, dueDate: "1/23/23"},
  { firstName: "Subham", lastName: "Abdul", email: "subham@yahoo.com", bday: "04/01/2001", weight: 140, height: "5'10\"", patientPhone: "123-123-1234", obgyn: "Dr. June", doctorPhone: "123-123-1234", epds: 0, dueDate: "1/23/23"},
  { firstName: "Anom", lastName: "Darin", email: "anom_d@gmail.com", bday: "01/19/2003", weight: 149, height: "5'9\"", patientPhone: "123-123-1234", obgyn: "Dr. Edwards", doctorPhone: "123-123-1234", epds: 0, dueDate: "1/23/23"},
  { firstName: "Meghan", lastName: "Fitcher", email: "meg_f@gmail.com", bday: "03/23/1990", weight: 131, height: "5'4\"", patientPhone: "123-123-1234", obgyn: "Dr. Doe", doctorPhone: "123-123-1234", epds: 0, dueDate: "1/23/23"},
  { firstName: "Subham", lastName: "Abdul", email: "subham@yahoo.com", bday: "04/01/2001", weight: 140, height: "5'10\"", patientPhone: "123-123-1234", obgyn: "Dr. June", doctorPhone: "123-123-1234", epds: 0, dueDate: "1/23/23"},
];

const Dashboard = () => {
  return (
    <>
    <OthersContainer>
      <div className="Patient table">
        <Header>Provider Dashboard</Header>
        <TableStyle style={{align: "center"}}>
          <Table data={data} />
        </TableStyle>
      </div>
    </OthersContainer>
    <Footer />
    </>
  );
};

export default Dashboard;
